import React, { useState, useEffect, useCallback, useContext } from 'react'
import { graphql } from 'gatsby'
import Helmet from 'react-helmet'

import App from '../components/App'
import ProductPage from '../components/ProductPage'
import InterfaceContext from '../contexts/InterfaceContext'
import StoreContext from '../contexts/StoreContext'
import { updateProduct } from '../utils/helpers'

const ProductPageTemplate = (props) => {
  const {
    data: {
      site,
      shopifyProduct: product,
      shopifyProduct: { title, description: fullDescription, handle },
    },
    location: { pathname },
  } = props

  const description = fullDescription
  const contentfulPlant = null

  const { client } = useContext(StoreContext)

  const [shopifyProduct, setShopifyProduct] = useState(product)

  const checkAvailability = useCallback(
    (productId) => {
      client.product.fetch(productId).then((fetchedProduct) => {
        updateProduct(shopifyProduct, fetchedProduct)
      })
    },
    [shopifyProduct]
  )

  useEffect(() => {
    checkAvailability(shopifyProduct.shopifyId)
  }, [shopifyProduct])

  return (
    <InterfaceContext.Consumer>
      {({ isDesktopViewport, productImageFeatured }) => (
        <App>
          <ProductPage
            product={shopifyProduct}
            plant={contentfulPlant}
            isDesktopViewport={isDesktopViewport}
            productImageFeatured={productImageFeatured}
          />
        </App>
      )}
    </InterfaceContext.Consumer>
  )
}

export default ProductPageTemplate

export const query = graphql`
  query($handle: String!) {
    shopifyProduct(handle: { eq: $handle }) {
      shopifyId
      id
      title
      handle
      description
      descriptionHtml
      productType
      tags

      variants {
        shopifyId
        id
        availableForSale
        image {
          id
          localFile {
            childImageSharp {
              fluid(maxWidth: 960, maxHeight: 1280) {
                ...GatsbyImageSharpFluid_withWebp
              }
            }
          }
        }
        price
        selectedOptions {
          name
          value
        }
        sku
        title
      }
      images {
        id
        localFile {
          childImageSharp {
            fluid(maxWidth: 960, maxHeight: 1280) {
              ...GatsbyImageSharpFluid_withWebp
            }
          }
        }
      }
    }
  }
`
