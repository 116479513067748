import React from 'react'
import PropTypes from 'prop-types'

import { colors, spacing, breakpoints } from '../../utils/styles'

const PlantInfo = ({ product, plant }) => (
  <div className="plantInfoRoot">
    <div className="plantCare">
      <h3>Plant Care</h3>
      <div className="plantInfoContentData">
        <p className="plantInfoTip">LIGHT</p>
        <p>{plant.light}</p>
      </div>
      <div className="plantInfoContentData">
        <p className="plantInfoTip">WATER</p>
        <p>{plant.water}</p>
      </div>
      <div className="plantInfoContentData">
        <p className="plantInfoTip">HUMIDITY</p>
        <p>{plant.humidity}</p>
      </div>
    </div>
    {plant.commonProblems && plant.commonProblems.length > 0 && (
      <div className="plantCommonProblems">
        <h3>Common Problems</h3>
        {plant.commonProblems.map((el) => (
          <div className="plantCommonProblems-content">
            <p className="plantInfoTip">{el.problem}</p>
            <p>{el.solution}</p>
          </div>
        ))}
      </div>
    )}

    <style jsx>{`
      .plantInfoRoot {
        background-color: ${colors.ligherGrey};
        width: 100%;
        padding: ${2 * spacing.xl}px;
        padding-bottom: ${2 * spacing.xl}px;
        display: flex;
        flex-direction: row;
      }
      .plantInfoRoot > * {
        width: calc(50% - ${spacing.xl}px);
      }

      .plantInfoContent {
        display: flex;
        flex-direction: column;
        margin-top: ${spacing.md}px;
      }
      .plantInfoContentData {
        display: flex;
        flex-direction: column;
        width: 100%;
        margin-top: ${spacing.lg}px;
      }
      .plantInfoTip {
        color: ${colors.text};
        font-weight: bold;
        text-transform: uppercase;
      }
      .plantCommonProblems {
        display: flex;
        flex-direction: column;
      }
      .plantCare {
        display: flex;
        flex-direction: column;
      }
      .plantCommonProblems-content {
        margin-top: ${spacing.lg}px;
      }
      @media (max-width: ${breakpoints.desktop}px) {
        .plantInfoRoot {
          padding: ${spacing.md}px;
          flex-direction: column;
        }
        .plantInfoRoot > * {
          width: 100%;
        }
        .plantInfoRoot > *:nth-child(n + 2) {
          margin-top: ${spacing.lg}px;
        }
      }
    `}</style>
  </div>
)

PlantInfo.propTypes = {
  plant: PropTypes.object.isRequired,
  product: PropTypes.object,
}

export default PlantInfo
