import React from 'react'
import PropTypes from 'prop-types'
import styled from '@emotion/styled'
import { Parser as HtmlToReactParser } from 'html-to-react'
const htmlToReactParser = new HtmlToReactParser()

import { productBenefit } from '../../utils/helpers'
import { breakpoints, colors, spacing } from '../../utils/styles'

const ProductSpecsRoot = styled(`div`)``

const Title = styled(`h3`)`
  color: ${colors.darkest};
  margin: 0;
  font-weight: 600;
  @media (max-width: ${breakpoints.tablet}px) {
    margin-top: ${spacing.md}px;
  }
`

const Subtitle = styled(`h4`)`
  color: ${colors.darkest};
  margin: 0;
  font-weight: 300;
  font-style: italic;
  margin-top: ${spacing['2xs']}px;
`

const Description = styled(`p`)`
  color: ${colors.text};
  font-size: 1rem;
  line-height: 1.5;
  margin-top: ${spacing.md}px;
  @media (min-width: ${breakpoints.tablet}px) {
    margin-top: ${spacing.xl}px;
  }
`

const ProductHighlight = styled(`div`)`
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-top: ${spacing.md}px;
  @media (min-width: ${breakpoints.tablet}px) {
    margin-top: ${spacing.xl}px;
  }
`
const Price = styled(`div`)`
  color: ${colors.text};
  font-size: 1.8rem;
  font-weight: 500;
  letter-spacing: -0.02em;

  margin-top: ${spacing.md}px;
  @media (min-width: ${breakpoints.tablet}px) {
    margin-top: ${spacing.xl}px;
  }
  span {
    color: ${colors.textMild};
  }
`

const ProductSpecs = props => {
  const { product, plant, variant } = props

  let title = product.title
  let subtitle = null
  console.log(product)
  let descriptionHtml = product.descriptionHtml

  if (plant) {
    title = plant.commonName
    subtitle = plant.originName
  }

  let productBenefitVar = productBenefit(product)
  return (
    <ProductSpecsRoot>
      <Title>{title}</Title>
      {subtitle && <Subtitle>{subtitle}</Subtitle>}
      <Price>{variant ? variant.price : ''} RON</Price>
      <Description>{htmlToReactParser.parse(descriptionHtml)}</Description>

      {productBenefitVar && productBenefitVar.image && (
        <ProductHighlight>
          <img src={productBenefitVar.image} />
          <span style={{ marginLeft: `${spacing.sm}px` }}>
            {productBenefitVar.title}
          </span>
        </ProductHighlight>
      )}
    </ProductSpecsRoot>
  )
}

ProductSpecs.propTypes = {
  product: PropTypes.object.isRequired,
  plant: PropTypes.object,
  variant: PropTypes.object,
}

export default ProductSpecs
