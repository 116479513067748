import React from 'react'
import PropTypes from 'prop-types'
import classNames from 'classnames'

import { Label } from '../shared/FormElements'
import { colors, spacing, breakpoints } from '../../utils/styles'

const ColorOptions = ({
  colorOptions,
  availableOptions,
  currentColor,
  plant,
  onColorChange,
}) => (
  <div className="flex-parent flex-parent--column colorOptions">
    <Label>Culori</Label>
    <div className="flex-parent flex-parent--row colorOptionsList flex-parent--wrap">
      {colorOptions.map(colorVariant => (
        <div
          key={colorVariant.title}
          className={classNames(
            'flex-parent flex-parent--column flex-parent--center-cross color-container',
            {
              'color-disabled':
                availableOptions.find(el => el.title === colorVariant.title) ===
                undefined,
            }
          )}
          onClick={event => {
            availableOptions.find(el => el.title === colorVariant.title) !==
              undefined && onColorChange(colorVariant)
          }}
        >
          <div
            className={classNames('color', {
              'color-selected':
                currentColor && currentColor.value === colorVariant.title,
            })}
            style={{
              backgroundColor: colorVariant.color,
            }}
          />
          <span>{colorVariant.title}</span>
        </div>
      ))}
    </div>
    <style jsx>{`
      .colorOptions {
        width: 100%;
        padding: ${spacing.md}px;
        padding-left: 0px;
        padding-top: ${spacing.sm}px;
        padding-bottom: ${spacing.sm}px;
      }
      .colorOptionsList .color-container:not(:last-child) {
        margin-right: ${spacing.lg}px;
      }
      .color {
        border-radius: 50%;
        width: 40px;
        height: 40px;

        box-shadow: 0 0 2px #888;
      }
      .color-container {
        cursor: pointer;
      }
      .color-container > span {
        font-size: 12px;
      }
      .color-selected {
        border: 1px solid ${colors.brand};
        box-shadow: unset;
      }
      .color-disabled {
        cursor: not-allowed;
        opacity: 0.5;
      }
    `}</style>
  </div>
)

ColorOptions.propTypes = {
  colorOptions: PropTypes.array.isRequired,
}

export default ColorOptions
