import React, {
  Component,
  useState,
  useEffect,
  useContext,
  useCallback,
} from 'react'
import PropTypes from 'prop-types'
import styled from '@emotion/styled'

import {
  MdErrorOutline,
  MdShoppingCart,
  MdSentimentDissatisfied,
} from 'react-icons/md'

import { Fieldset, Input, Label, Select, Submit } from '../shared/FormElements'

import { breakpoints, colors, spacing, radius } from '../../utils/styles'
import {
  availablePlantersTypes,
  availableProductColors,
  availableProductSizes,
  allProductSizes,
  PLANTER,
  SIZE,
  COLOR,
  colorOptions,
} from '../../utils/helpers'

import StoreContext from '../../contexts/StoreContext'
import ProductColorOptions from './ProductColorOptions'
import ProductSizeOptions from './ProductSizeOptions'

const Form = styled(`form`)`
  display: flex;
  flex-wrap: wrap;
  justify-content: center;

  @media (min-width: ${breakpoints.desktop}px) {
    justify-content: flex-start;
  }
`

const Errors = styled(`div`)`
  display: ${(props) => (props.show ? 'flex' : 'none')};
  flex-direction: row;
  margin-bottom: ${spacing.xs}px;
  width: 100%;
`

const ErrorSign = styled(`div`)`
  align-items: center;
  background: ${colors.red};
  border-radius: ${radius.default}px 0 0 ${radius.default}px;
  color: ${colors.lightest};
  display: flex;
  flex-basis: 40px;
  justify-content: center;

  svg {
    height: 20px;
    width: 20px;
  }
`

const ErrorMsgs = styled(`ul`)`
  border: 1px solid ${colors.red};
  border-left: none;
  border-radius: 0 ${radius.default}px ${radius.default}px 0;
  color: ${colors.error};
  flex-grow: 1;
  margin: 0;
  padding: ${spacing.xs}px;
  padding-left: ${spacing.xl}px;
`

const ColorFieldset = styled(Fieldset)`
  flex-basis: calc(100% - ${spacing.md}px - 70px);

  ${Label} {
    justify-content: space-between;
    padding-left: 0;
  }
`

const AddToCartButton = styled(Submit)`
  align-self: flex-end;
  flex-grow: 1;
  height: ${(props) => (props.fullWidth ? 'auto' : '')};
  width: ${(props) => (props.fullWidth ? '100%' : 'auto')};

  ${(props) =>
    props.disabled &&
    `background: ${colors.red}; &:hover {background: ${colors.red}}`}
`

const Price = styled(`div`)`
  letter-spacing: -0.02em;

  margin-left: ${spacing['2xs']}px;
`

const optionsForVariant = (variant) => {
  let currentColor = null
  let currentPlanter = null
  let currentSize = null

  if (variant) {
    currentColor = variant.selectedOptions.find((el) => el.name === COLOR)
    currentPlanter = variant.selectedOptions.find((el) => el.name === PLANTER)
    currentSize = variant.selectedOptions.find((el) => el.name === SIZE)
  }

  return {
    currentColor: currentColor,
    currentPlanter: currentPlanter,
    currentSize: currentSize,
  }
}

const findVariant = (variants, optionsToCheck) => {
  if (!variants) {
    return null
  }

  return variants.find((variant) => {
    return optionsToCheck.every((optionCheck) =>
      variant.selectedOptions.some(
        (checkAgainst) =>
          optionCheck.name === checkAgainst.name &&
          optionCheck.value === checkAgainst.value
      )
    )
  })
}

const filterVariants = (variants, optionsToCheck) => {
  var filtered = optionsToCheck.filter(function (el) {
    return el != null
  })

  return variants.filter((variant) => {
    return filtered.every((optionCheck) =>
      variant.selectedOptions.some(
        (checkAgainst) =>
          optionCheck.name === checkAgainst.name &&
          optionCheck.value === checkAgainst.value
      )
    )
  })
}

const ProductForm = (props) => {
  const {
    variant,
    product,
    product: { variants },
    onHandleWaitlist,
  } = props

  // console.log(variants)
  const variantOptions = optionsForVariant(variant)

  const [productData, setProductData] = useState({
    variantId: variant
      ? variant.shopifyId
      : variants.length === 1
      ? variants[0].shopifyId
      : '',
    quantity: 1,
    errors: [],
    ...variantOptions,
  })

  useEffect(() => {
    handleChange()
  }, [
    productData.currentColor,
    productData.currentPlanter,
    productData.currentSize,
  ])

  const { addVariantToCart, client, adding } = useContext(StoreContext)

  const available = variant ? variant.availableForSale : false
  const handleChange = () => {
    const {
      product: { variants },
    } = props
    const { currentColor, currentPlanter, currentSize } = productData

    const optionsToCheck = []
    if (currentColor) optionsToCheck.push(currentColor)
    if (currentPlanter) optionsToCheck.push(currentPlanter)
    if (currentSize) optionsToCheck.push(currentSize)

    //find variant for selected options
    let filteredVariant = findVariant(variants, optionsToCheck)
    if (!filteredVariant) {
      console.log('could not find variant')
      const shouldShowPlanterOptions =
        product.tags.indexOf('type:live-plant') !== -1

      if (shouldShowPlanterOptions) {
        filteredVariant = findVariant(variants, [currentPlanter, currentSize])
        if (!filteredVariant)
          filteredVariant = findVariant(variants, [currentPlanter])
      } else {
        filteredVariant = findVariant(variants, [currentSize])
        if (!filteredVariant) filteredVariant = findVariant(variants, [])
      }
    }

    if (filteredVariant) {
      const variantOptions = optionsForVariant(filteredVariant)
      setProductData({
        ...productData,
        variantId: filteredVariant.shopifyId,
        ...variantOptions,
      })

      const { onVariantChanged } = props
      onVariantChanged(filteredVariant)
    }
  }

  const handleSubmit = (callback) => (event) => {
    event.preventDefault()

    const errors = []

    if (productData.variantId === '' || productData.variantId === '.') {
      errors.push({
        field: 'variant',
        msg: 'Te rugam sa alegi o <b>marime</b>.',
      })
    }

    if (errors.length) {
      setProductData({ ...productData, errors: errors })
      return
    }

    callback(productData.variantId, productData.quantity)
  }

  const {
    errors,
    currentColor,
    currentPlanter,
    currentSize,
    variantId,
  } = productData

  const optionsToCheck = []
  if (currentPlanter) optionsToCheck.push(currentPlanter)
  if (currentSize) optionsToCheck.push(currentSize)

  let filteredVariants = filterVariants(variants, optionsToCheck)
  let filteredVariantByPlanter = filterVariants(variants, [currentPlanter])

  const hasVariants = variants.length >= 1
  const shouldShowPlanterOptions =
    product.tags.indexOf('type:live-plant') !== -1

  const plantersTypes = availablePlantersTypes(product)
  const productColors = availableProductColors(filteredVariants)
  const productSizes = availableProductSizes(variants, product.tags)
  let availableSizes = availableProductSizes(variants, product.tags)
  if (shouldShowPlanterOptions)
    availableSizes = availableProductSizes(
      filteredVariantByPlanter,
      product.tags
    )

  return (
    <Form onSubmit={handleSubmit(addVariantToCart)} noValidate>
      {errors && (
        <Errors show={errors.length}>
          <ErrorSign>
            <MdErrorOutline />
          </ErrorSign>
          <ErrorMsgs>
            {errors.map((error) => (
              <li
                key={error.field}
                dangerouslySetInnerHTML={{ __html: error.msg }}
              />
            ))}
          </ErrorMsgs>
        </Errors>
      )}

      {hasVariants && (
        <>
          <ColorFieldset>
            {shouldShowPlanterOptions && (
              <>
                <Label htmlFor="currentPlanter">Ghiveci</Label>
                <Select
                  id="currentPlanter"
                  name={PLANTER}
                  value={currentPlanter ? currentPlanter.value : ''}
                  onChange={(event) => {
                    setProductData({
                      ...productData,
                      currentPlanter: {
                        name: event.target.name,
                        value: event.target.value,
                      },
                    })
                  }}
                >
                  <option disabled value="">
                    Alege ghiveci
                  </option>
                  {plantersTypes.map((option) => (
                    <option value={option.value} key={option.value}>
                      {option.value}
                    </option>
                  ))}
                </Select>
              </>
            )}
            {availableSizes.length > 0 && (
              <ProductSizeOptions
                options={productSizes}
                availableOptions={availableSizes}
                currentOption={currentSize}
                onOptionChange={(option) => {
                  setProductData({
                    ...productData,
                    currentSize: {
                      name: SIZE,
                      value: option,
                    },
                  })
                }}
              />
            )}
            {productColors.length > 0 && (
              <ProductColorOptions
                colorOptions={colorOptions}
                availableOptions={productColors}
                currentColor={currentColor}
                onColorChange={(colorOption) => {
                  setProductData({
                    ...productData,
                    currentColor: {
                      name: COLOR,
                      value: colorOption.title,
                    },
                  })
                }}
              />
            )}
          </ColorFieldset>
        </>
      )}
      <AddToCartButton
        type="submit"
        disabled={adding}
        fullWidth={hasVariants}
        onClick={(event) => {
          if (!available) {
            event.preventDefault()
            onHandleWaitlist && onHandleWaitlist()
          }
        }}
      >
        {!available
          ? 'Stoc epuizat - Adauga-ma pe lista de asteptare!'
          : `Adauga in cos -`}
        {available && <Price>{variant ? variant.price : ''} RON</Price>}
      </AddToCartButton>
    </Form>
  )
}

ProductForm.propTypes = {
  id: PropTypes.string.isRequired,
  variant: PropTypes.object,
  product: PropTypes.object,
  onVariantChanged: PropTypes.func,
  onHandleWaitlist: PropTypes.func,
}

export default ProductForm
