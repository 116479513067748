import React from 'react'
import Modal from 'react-modal'

import Link from '../Link'
import CloseButton from './CloseButton'

import { colors } from '../../utils/styles'

const CustomModal = ({ isOpen, onClose, children, title }) => {
  return (
    <Modal
      isOpen={isOpen}
      onRequestClose={onClose}
      className="customModal"
      overlayClassName="customModalOverlay"
    >
      <div className="modalWrapper">
        {title && <h4 className="header">{title}</h4>}
        <div className="closeButton">
          <CloseButton
            onClick={onClose}
            size={24}
            color={colors.brand}
          ></CloseButton>
        </div>
        <div className="content">{children}</div>
      </div>
      <style jsx>{`
        :global(.customModalOverlay) {
          position: fixed;
          top: 0;
          left: 0;
          right: 0;
          bottom: 0;
          background-color: rgba(0, 0, 0, 0.3);
        }
        :global(.customModal) {
          outline: none;
          position: relative;
          top: 50%;
          left: 50%;
          right: auto;
          bottom: auto;
          transform: translate(-50%, -50%);
          max-width: 550px;
          padding: 0px;
          background: white;
          border: 1px solid ${colors.ligherGrey};
          border-radius: 4px;
        }
        .modalWrapper {
          position: relative;
          padding: 40px;
        }
        .header {
          margin-bottom: 12px;
        }
        .closeButton {
          position: absolute;
          top: 12px;
          right: 12px;
        }
        @media (max-width: 767px) {
          :global(.customModal) {
            position: fixed;
            width: 100%;
            max-width: 100%;
            top: unset;
            bottom: 0%;
            left: unset;
            transform: unset;
            z-index: 999;
          }
          .modalWrapper {
            padding: 12px;
          }
        }
      `}</style>
    </Modal>
  )
}

export default CustomModal
