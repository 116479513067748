import React, { Component } from 'react'
import PropTypes from 'prop-types'
import Image from 'gatsby-image'
import styled from '@emotion/styled'
import { keyframes } from '@emotion/core'

import { breakpoints, colors, radius, spacing } from '../../utils/styles'

export const IMAGE_CHANGE_ANIM_DURATION = 250

const change = keyframes`
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
`

const ProductImageLink = styled(`a`)`
  display: block;
  position: relative;
  cursor: default;
  &.change {
    animation: ${change} ${IMAGE_CHANGE_ANIM_DURATION}ms ease-out forwards;
  }
`

class ProductImage extends Component {
  imageLink

  componentDidUpdate = (prevProps) => {
    if (
      prevProps.image &&
      this.props.image &&
      prevProps.image.id !== this.props.image.id
    ) {
      this.imageLink.classList.add('change')

      setTimeout(
        () => this.imageLink.classList.remove('change'),
        IMAGE_CHANGE_ANIM_DURATION
      )
    }
  }

  handleClick = (callback) => (event) => {
    event.preventDefault()

    callback && callback(this.props.image)
  }

  render() {
    const { image, onClick, imageFeatured = null } = this.props

    if (!image) return null

    const {
      localFile: {
        childImageSharp: { fluid },
      },
    } = image

    return (
      <ProductImageLink
        ref={(el) => {
          this.imageLink = el
        }}
        href={fluid.src}
        onClick={this.handleClick(onClick)}
      >
        <Image
          fluid={imageFeatured ? featuredFluid : fluid}
          alt=""
          style={{ width: '100%' }}
        />
      </ProductImageLink>
    )
  }
}

ProductImage.propTypes = {
  image: PropTypes.object,
  onClick: PropTypes.func,
  imageFeatured: PropTypes.object,
}

export default ProductImage
