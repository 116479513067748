import React from 'react'
import PropTypes from 'prop-types'
import classNames from 'classnames'

import { Label } from '../shared/FormElements'
import { colors, spacing, breakpoints } from '../../utils/styles'

const ProductSizeOptions = ({
  options,
  availableOptions,
  currentOption,
  plant,
  onOptionChange,
}) => (
  <div className="flex-parent flex-parent--column options">
    <Label>Marime</Label>
    <div className="flex-parent flex-parent--row optionsList">
      {options.map(option => (
        <span
          key={option}
          className={classNames('size', {
            'size-disabled': availableOptions.indexOf(option) === -1,
            'size-selected': currentOption && currentOption.value === option,
          })}
          onClick={event => {
            availableOptions.indexOf(option) !== -1 && onOptionChange(option)
          }}
        >
          {option}
        </span>
      ))}
    </div>
    <style jsx>{`
      .options {
        width: 100%;
        padding: ${spacing.md}px;
        padding-left: 0px;
        padding-top: ${spacing.sm}px;
        padding-bottom: ${spacing.sm}px;
      }
      .optionsList .size:not(:last-child) {
        margin-right: ${spacing.lg}px;
      }
      .size {
        cursor: pointer;

        color: ${colors.textMild};
      }
      .size-selected {
        color: ${colors.text}!important;
        font-weight: bold;
      }
      .size-disabled {
        color: ${colors.textLighter};
        cursor: not-allowed;
      }
    `}</style>
  </div>
)

ProductSizeOptions.propTypes = {
  options: PropTypes.array.isRequired,
}

export default ProductSizeOptions
