import React from 'react'
import PropTypes from 'prop-types'
import styled from '@emotion/styled'
import { MdCameraAlt } from 'react-icons/md'
import Slider from 'react-slick'
import Helmet from 'react-helmet'

import ProductImage from './ProductImage'

import { breakpoints, colors, radius, spacing } from '../../utils/styles'

const ProductImagesMobileRoot = styled(`div`)`
  display: flex;
  justify-content: center;
  padding: ${spacing.md}px;
  padding-bottom: ${spacing.xs}px;
  width: 100%;

  @media (min-width: ${breakpoints.tablet}px) {
    padding: ${spacing.xl}px;
    padding-bottom: ${spacing.lg}px;
  }
  @media (max-width: ${breakpoints.tablet}px) {
    padding: 0;
    padding-bottom: 0;
  }
`

const ProductImagesMobileContent = styled(`div`)`
  width: 100%;
  @media (min-width: ${breakpoints.phablet}px) and (max-width: ${breakpoints.desktop}px) {
    width: 70%;
  }
`

const ProductImagesMobile = ({ images }) => {
  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
  }

  return (
    <>
      <Helmet>
        <link
          rel="stylesheet"
          type="text/css"
          charset="UTF-8"
          href="https://cdnjs.cloudflare.com/ajax/libs/slick-carousel/1.6.0/slick.min.css"
        />
        <link
          rel="stylesheet"
          type="text/css"
          href="https://cdnjs.cloudflare.com/ajax/libs/slick-carousel/1.6.0/slick-theme.min.css"
        />
      </Helmet>
      <ProductImagesMobileRoot>
        <ProductImagesMobileContent>
          <Slider {...settings}>
            {images.map((image, idx) => (
              <ProductImage key={idx} image={image} onClick={() => {}} />
            ))}
            <style jsx>{`
              :global(.slick-dots) {
                bottom: 10px !important;
              }
            `}</style>
          </Slider>
        </ProductImagesMobileContent>
      </ProductImagesMobileRoot>
    </>
  )
}

ProductImagesMobile.propTypes = {
  images: PropTypes.array.isRequired,
}

export default ProductImagesMobile
