import React, { useState } from 'react'
import classNames from 'classnames'
import PulseLoader from 'react-spinners/PulseLoader'
import qs from 'qs'

import { Input } from './shared/FormElements'
import { PrimaryButton } from './shared/Buttons'

const WaitlistModal = ({ product, currentVariant, onClose }) => {
  const [state, setState] = useState({
    waitlistLoading: false,
    waitlistEmail: '',
    waitlistEmailHoney: '',
    waitlistSuccess: null,
  })

  const waitlistString = () => {
    if (state.waitlistSuccess === true) return 'Abonare cu succes'
    if (state.waitlistSuccess === false) return 'Abonare esuata '
    return 'Anunta-ma cand revine in stoc'
  }

  return (
    <div>
      {state.waitlistSuccess === true ? (
        <div className="mt16">
          <p>
            Te-am adaugat pe lista de asteptare. Te anuntam de indata ce
            produsul revine in stoc!
          </p>
          <PrimaryButton
            onClick={onClose}
            style={{ width: '100%' }}
            className="mt16"
          >
            Inchide
          </PrimaryButton>
        </div>
      ) : (
        <>
          <p>
            Introdu adresa ta de email si te anuntam cand produsul revine in
            stoc.
          </p>
          <form
            className="mt16 mb4"
            onSubmit={async (e) => {
              e.preventDefault()

              if (state.waitlistEmailHoney.length > 0) {
                //SPAM
                return
              }

              setState({
                ...state,
                waitlistLoading: true,
              })

              try {
                const response = await fetch('/api/waitlist', {
                  method: 'POST',
                  body: qs.stringify({
                    email: state.waitlistEmail,
                    product: product.id,
                    currentVariant: currentVariant.id,
                    url: location.href,
                  }),
                  headers: {
                    'content-type':
                      'application/x-www-form-urlencoded;charset=utf-8',
                  },
                })

                if (!response.ok) {
                  //not 200 response
                  setState({
                    ...state,
                    waitlistLoading: false,
                    waitlistSuccess: false,
                  })
                  return
                }

                //all OK
                setState({
                  ...state,
                  waitlistLoading: false,
                  waitlistSuccess: true,
                })
              } catch (e) {
                setState({
                  ...state,
                  waitlistLoading: false,
                  waitlistSuccess: false,
                })
              }
            }}
          >
            <Input
              type="text"
              name="emaildasdas"
              onChange={(event) => {
                setState({
                  ...state,
                  waitlistEmail: event.target.value,
                })
              }}
              value={state.waitlistEmail}
              placeholder="Email"
              className="mb16"
            />
            <Input
              type="text"
              name="email"
              onChange={(event) => {
                setState({
                  ...state,
                  waitlistEmailHoney: event.target.value,
                })
              }}
              value={state.waitlistEmailHoney}
              placeholder="Email"
              className="ohnohoney"
            />
            <PrimaryButton
              onClick={() => {}}
              style={{ width: '100%' }}
              disabled={
                state.waitlistEmail.length === 0 ||
                state.waitlistLoading ||
                state.waitlistSuccess !== null
              }
              type="submit"
              className={classNames({
                error: state.waitlistSuccess === false,
              })}
            >
              {state.waitlistLoading === true ? (
                <PulseLoader size={10} color={'white'} loading={true} />
              ) : (
                <>{waitlistString()}</>
              )}
            </PrimaryButton>
          </form>
        </>
      )}
    </div>
  )
}

export default WaitlistModal
