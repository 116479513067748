import React, { Component } from 'react'
import PropTypes from 'prop-types'
import styled from '@emotion/styled'
import differenceBy from 'lodash/differenceBy'
import qs from 'qs'
import { navigate } from '@reach/router'
import classNames from 'classnames'

import ProductImagesMobile from './ProductImagesMobile'
import ProductImagesDesktop from './ProductImagesDesktop'
import ProductSpecs from './ProductSpecs'
import ProductForm from './ProductForm'
import PlantInfo from './PlantInfo'
import CustomModal from '../shared/Modal'
import WaitlistModal from '../WaitlistModal'

import { breakpoints, spacing } from '../../utils/styles'
import { getUnique } from '../../utils/helpers'

const ProductPageRoot = styled('div')`
  padding-bottom: ${spacing.md}px;

  @media (min-width: ${breakpoints.desktop}px) {
    align-items: center;
    display: flex;
    flex-direction: column;
    min-height: calc(100vh);
    width: 100%;
    padding-top: ${spacing.xl}px;
  }
`

const Container = styled(`div`)`
  width: 100%;
  padding: ${spacing.md}px;
  @media (min-width: ${breakpoints.desktop}px) {
    align-items: flex-start;
    justify-content: center;
    display: flex;
  }
`

const ContentWraper = styled('div')`
  margin-top: ${spacing.md}px;

  @media (min-width: ${breakpoints.desktop}px) {
    margin-top: ${spacing.xl}px;
  }
`

const Details = styled(`div`)`
  position: relative;

  @media (min-width: ${breakpoints.desktop}px) {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    max-width: 40%;
    min-width: 40%;
    min-height: 490px;
  }
`

const PlantInfoWrapper = styled('div')`
  margin-top: ${spacing.md}px;
  width: 100%;
  @media (min-width: ${breakpoints.desktop}px) {
    margin-top: ${spacing.xl}px;
  }
`

class ProductPage extends Component {
  constructor(props) {
    super(props)

    //get variant from query string, can be null
    this.state = {
      currentVariant: this.getCurrentVariant(),
      waitlistModalOpen: false,
    }
  }

  componentDidMount() {
    //redirect to first variant if qs has no variant
    const urlQueryString = qs.parse(window.location.search.replace('?', ''))
    if (!urlQueryString.variant) {
      const {
        product,
        product: { variants },
      } = this.props

      const firstVariant = variants.length > 0 ? variants[0] : null
      if (firstVariant) {
        navigate(
          `${window.location.pathname}?variant=${firstVariant.shopifyId}`
        )
      }
    } else {
      const variant = this.props.product.variants.find(
        (el) => el.shopifyId === urlQueryString.variant
      )
    }
  }

  componentDidUpdate(prevProps, prevState) {
    var urlQueryString = qs.parse(window.location.search.replace('?', ''))

    if (
      (urlQueryString.variant &&
        prevState.currentVariant &&
        prevState.currentVariant.shopifyId !== urlQueryString.variant) ||
      (!prevState.currentVariant && urlQueryString.variant)
    ) {
      const newVariant = this.props.product.variants.find(
        (el) => el.shopifyId === urlQueryString.variant
      )
      this.setState({ currentVariant: newVariant })
    }
  }

  getCurrentVariant = () => {
    if (typeof window === 'undefined') return null
    var urlQueryString = qs.parse(window.location.search.replace('?', ''))

    if (!urlQueryString.variant) {
      return null
    }
    return this.props.product.variants.find(
      (el) => el.shopifyId === urlQueryString.variant
    )
  }

  variantImages = (product, variant) => {
    const productVariantImages = product.variants.map((el) => el.image)

    const differenceImages = differenceBy(
      product.images,
      productVariantImages,
      'id'
    )

    if (!variant) return differenceImages

    let images = getUnique([variant.image, ...differenceImages], 'id')
    return images
  }

  onToggleWaitlistModal = () => {
    this.setState({
      waitlistModalOpen: !this.state.waitlistModalOpen,
    })
  }

  render() {
    const {
      product,
      product: { id, variants, description },
      plant,
    } = this.props

    const { isDesktopViewport, productImageFeatured } = this.props

    const { currentVariant, waitlistModalOpen } = this.state
    const images = this.variantImages(product, currentVariant)

    console.log({ currentVariant })

    return (
      <ProductPageRoot>
        <Container>
          {!isDesktopViewport ? (
            <ProductImagesMobile images={images} />
          ) : (
            <ProductImagesDesktop
              images={images}
              imageFeatured={productImageFeatured}
            />
          )}
          <Details>
            <ProductSpecs
              product={product}
              plant={plant}
              variant={currentVariant}
            />
            <ContentWraper>
              <ProductForm
                id={id}
                variant={currentVariant}
                product={product}
                onVariantChanged={(variant) => {
                  navigate(
                    `${window.location.pathname}?variant=${variant.shopifyId}`
                  )
                }}
                onHandleWaitlist={() => {
                  this.onToggleWaitlistModal()
                }}
              />
            </ContentWraper>
          </Details>
        </Container>
        {plant && (
          <PlantInfoWrapper>
            <PlantInfo product={product} plant={plant} />
          </PlantInfoWrapper>
        )}
        <CustomModal
          isOpen={waitlistModalOpen}
          onClose={this.onToggleWaitlistModal}
          title={product.title}
        >
          <WaitlistModal
            product={product}
            currentVariant={currentVariant}
            onClose={this.onToggleWaitlistModal}
          />
        </CustomModal>
      </ProductPageRoot>
    )
  }
}

ProductPage.propTypes = {
  product: PropTypes.object.isRequired,
  plant: PropTypes.object,
  productImageFeatured: PropTypes.object,
  isDesktopViewport: PropTypes.bool,
}

export default ProductPage
